import '@/scss/app.scss'

// Import all modules
import sayHello from './modules/hello'
import global from './modules/global'
import forms from './modules/forms'

// App main
const main = async () => {
  if (import.meta.env.DEV) {
    sayHello.init()
  }

  global.init()
  forms.init()
}

// Execute async function
main().then(() => {
  console.log()
})

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  })
}
