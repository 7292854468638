const global = {
    name: 'global',
    init: function() {
        console.log('Global module initialized');

        // Toggle functionality
        document.querySelectorAll('.js-toggle').forEach(toggle => {
            toggle.addEventListener('click', function() {
                if (!this.classList.contains('opened')) {
                    // Close any open ones
                    document.querySelectorAll('.myinfo').forEach(info => info.style.display = 'none');
                    document.querySelectorAll('.js-toggle').forEach(t => t.classList.remove('opened'));
                    
                    // Open this one
                    this.classList.add('opened');
                    this.querySelector('.myinfo').style.display = 'block';
                } else {
                    // Close this one
                    this.classList.remove('opened');
                    this.querySelector('.myinfo').style.display = 'none';
                }
            });
        });

        // Open close menu mobile
        const menuMobile = document.getElementById('button-left');
        if (menuMobile) {
            menuMobile.addEventListener('click', () => {
                const snapDrawer = document.querySelector('.snap-drawer');
                let content = document.getElementById('contentWrap');

                if (content) {
                    if (snapDrawer.classList.contains('is-open')) {
                        content.style.transform = `translate3d(0px, 0px, 0px)`;
                        content.style.transition = '0.3s';
                        snapDrawer.classList.remove('is-open');
                    } else {
                        content.style.transform = `translate3d(${snapDrawer.offsetWidth}px, 0px, 0px)`;
                        content.style.transition = '0.3s';
                        snapDrawer.classList.add('is-open');
                    }
                } else {
                    content = document.getElementById('page');
                    if (content) {
                        if (snapDrawer.classList.contains('is-open')) {
                            content.style.transform = `translate3d(0px, 0px, 0px)`;
                            content.style.transition = '0.3s';
                            snapDrawer.classList.remove('is-open');
                        } else {
                            content.style.transform = `translate3d(${snapDrawer.offsetWidth}px, 0px, 0px)`;
                            content.style.transition = '0.3s';
                            snapDrawer.classList.add('is-open');
                        }
                    }
                }
            });
        }

        const closeSnap = document.getElementById('close-snap');
        if (closeSnap) {
            closeSnap.addEventListener('click', () => {
                let content = document.getElementById('contentWrap');
                const snapDrawer = document.querySelector('.snap-drawer');
                if (content) {
                    if (snapDrawer.classList.contains('is-open')) {
                        content.style.transform = `translate3d(0px, 0px, 0px)`;
                        content.style.transition = '0.3s';
                        snapDrawer.classList.remove('is-open');
                    } else {
                        content.style.transform = `translate3d(${snapDrawer.offsetWidth}px, 0px, 0px)`;
                        content.style.transition = '0.3s';
                        snapDrawer.classList.add('is-open');
                    }
                } else {
                    content = document.getElementById('page');
                    if (content) {
                        if (snapDrawer.classList.contains('is-open')) {
                            content.style.transform = `translate3d(0px, 0px, 0px)`;
                            content.style.transition = '0.3s';
                            snapDrawer.classList.remove('is-open');
                        } else {
                            content.style.transform = `translate3d(${snapDrawer.offsetWidth}px, 0px, 0px)`;
                            content.style.transition = '0.3s';
                            snapDrawer.classList.add('is-open');
                        }
                    }
                }
            });
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth >= 875) {
                let content = document.getElementById('contentWrap');
                const snapDrawer = document.querySelector('.snap-drawer');
                if (content) {
                    content.style.transform = `translate3d(0px, 0px, 0px)`;
                    content.style.transition = '0.3s';
                    snapDrawer.classList.remove('is-open');
                } else {
                    content = document.getElementById('page');
                    if (content) {
                        content.style.transform = `translate3d(0px, 0px, 0px)`;
                        content.style.transition = '0.3s';
                        snapDrawer.classList.remove('is-open');
                    }
                }
            }
        });

        // Height adjustment functions
        // const adjustHeight = () => {
        //     const homePage = document.querySelector('.home-page');
        //     if (homePage) {
        //         const contentHeight = homePage.offsetHeight + 200;
        //         document.querySelectorAll('.cb-slideshow, .cb-slideshow:after, .cb-slideshow li span')
        //             .forEach(el => el.style.height = contentHeight + 'px');
        //     }
        // };

        // const adjustHeight2 = () => {
        //     const productsPage = document.querySelector('.products-page');
        //     if (productsPage) {
        //         const contentHeight = productsPage.offsetHeight;
        //     }
        // };

        // Initial height adjustments
        // adjustHeight();
        // adjustHeight2();

        // Resize listeners
        // window.addEventListener('resize', adjustHeight);
        // window.addEventListener('resize', adjustHeight2);

        // Services expander
        const services = document.getElementById('services');
        if (services) {
            services.addEventListener('click', (e) => {
                if (e.target.closest('.service-basic')) {
                    e.preventDefault();
                    
                    services.querySelectorAll('.service-cell').forEach(cell => {
                        cell.classList.remove('is-expanded');
                    });
                    
                    const parentCell = e.target.closest('.service-cell');
                    parentCell.classList.add('is-expanded');
                    
                    const servicesGrid = services.querySelector('.services-grid');
                    if (!services.querySelector('.animation-on')) {
                        servicesGrid.classList.add('animation-on');
                    } else {
                        servicesGrid.classList.remove('animation-on');
                        servicesGrid.classList.add('animation-off');
                    }
                }
                
                if (e.target.hasAttribute('close-expand')) {
                    e.preventDefault();
                    services.querySelectorAll('.service-cell').forEach(cell => {
                        cell.classList.remove('is-expanded');
                    });
                    const servicesGrid = services.querySelector('.services-grid');
                    servicesGrid.classList.remove('animation-on', 'animation-off');
                }
            });
        }

        // Smooth scroll
        document.querySelectorAll('a.scroll').forEach(link => {
            link.addEventListener('click', () => {
                const href = link.getAttribute('href');
                const targetId = href.substring(href.indexOf('#'));
                const target = document.querySelector(targetId);
                
                if (target) {
                    window.scrollTo({
                        top: target.offsetTop,
                        behavior: 'smooth'
                    });
                }
            });
        });
    }
};

export default global;