const forms = {
    name: 'forms',
    init: function() {
        console.log('Forms module initialized');

        // const speed = 300;
        // const delay = 5000;

        // Submit button click handler
        document.querySelectorAll('.submit').forEach(button => {
            button.addEventListener('click', () => {
                button.closest('form').submit();
            });
        });

        // Form submission handler
        document.querySelectorAll('form.ajax').forEach(form => {
            form.addEventListener('submit', e => {
                e.preventDefault();

                const ajaxUrl = form.dataset.call;
                const emailInput = form.querySelector('input[name="email"]');

                if (emailInput && emailInput.value === 'info@domainregistrationcorp.com') {
                    alert('error');
                    return;
                }

                const formData = new FormData(form);
                const preloader = form.querySelector('.preloader');
                
                // Remove any existing validation errors
                form.querySelectorAll('.validation-error-inline').forEach(el => el.remove());

                // Show preloader
                if (preloader) preloader.style.display = 'block';

                fetch('/' + ajaxUrl, {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(data => {
                    if (preloader) preloader.style.display = 'none';

                    if (data.validation_failed === 1) {
                        // Handle validation errors
                        Object.entries(data.errors).forEach(([field, errors]) => {
                            if (errors.length > 0) {
                                const input = form.querySelector(`[name=${field}]`);
                                if (input) {
                                    const error = document.createElement('span');
                                    error.className = 'validation-error-inline';
                                    error.textContent = errors[0];
                                    input.after(error);
                                }
                            }
                        });
                    } else {
                        // Handle successful submission
                        let hideEl, showEl, overlay, targetOverlay, holder;
                        
                        switch (data.action) {
                            case 'toggle':
                                hideEl = form.closest(`:has(${data.hide_element})`).querySelector(data.hide_element);
                                showEl = form.closest(`:has(${data.show_element})`).querySelector(data.show_element);
                                
                                if (hideEl) hideEl.style.display = 'none';
                                if (showEl) {
                                    showEl.innerHTML = data.message;
                                    showEl.style.display = 'block';
                                }
                                break;

                            case 'redirect':
                                window.location = data.url;
                                break;

                            case 'show_overlay':
                                overlay = document.querySelector('.overlay');
                                if (overlay) overlay.style.display = 'block';

                                targetOverlay = document.querySelector(data.overlay);
                                if (targetOverlay) {
                                    const title = targetOverlay.querySelector('h3');
                                    const points = targetOverlay.querySelector('.points-awarded');
                                    
                                    if (title) title.innerHTML = data.message;
                                    if (points) points.innerHTML = data.points;
                                    targetOverlay.style.display = 'block';
                                }

                                if (ajaxUrl === 'profile/feed') {
                                    const scoreEl = document.getElementById('score-current-week');
                                    const dateEl = document.getElementById('upd-date');
                                    const textEl = document.getElementById('upd-text');
                                    const pointsHolder = document.querySelector('.points-holder');

                                    if (scoreEl) {
                                        scoreEl.style.display = 'none';
                                        scoreEl.innerHTML = data.score.score;
                                        scoreEl.style.display = 'block';
                                    }
                                    if (dateEl) {
                                        dateEl.style.display = 'none';
                                        dateEl.innerHTML = data.score.update_date;
                                        dateEl.style.display = 'block';
                                    }
                                    if (textEl) textEl.style.display = 'block';
                                    if (pointsHolder) pointsHolder.innerHTML = data.html;
                                }
                                break;

                            case 'show_message_fadeout':
                                holder = document.querySelector(data.holder);
                                if (holder) {
                                    holder.innerHTML = data.message;
                                    setTimeout(() => {
                                        holder.style.display = 'none';
                                        setTimeout(() => {
                                            holder.innerHTML = '';
                                            holder.style.display = 'block';
                                        }, 100);
                                    }, 4000);
                                }
                                break;
                        }

                        if (form.dataset.clean || typeof form.dataset.clean === 'undefined') {
                            cleanForm(form);
                        }

                        form.querySelectorAll('input').forEach(input => {
                            input.removeAttribute('disabled');
                        });
                    }
                })
                .catch(error => {
                    if (preloader) preloader.style.display = 'none';
                    console.error('Form submission error:', error);
                });
            });
        });

        function cleanForm(form) {
            form.querySelectorAll('input').forEach(input => {
                if (!['submit', '_token'].includes(input.name) && input.type !== 'checkbox') {
                    input.value = '';
                }
                if (input.type === 'checkbox') {
                    input.checked = false;
                }
            });
        }
    }
};

export default forms;